import React from 'react';

interface IInlineTag {}

const InlineTag: React.FC<IInlineTag> = (props) => {
    const { children } = props;
    return (
        <span className="inline text-white text-sm bg-gray-600 px-3 py-1 rounded-xl mr-1 mb-1 whitespace-nowrap">
            {children}
        </span>
    );
};
export default InlineTag;
