import React, { FunctionComponent } from 'react';
import NewsletterCTA from '../NewsletterCTA/NewsletterCTA';
import AboutBlock from '../AboutBlock/AboutBlock';
import ShareRow from '../ShareRow/ShareRow';

const Sidebar: FunctionComponent = () => (
    <div className="relative flex flex-col p-3 md:p-6">
        <NewsletterCTA />
        <div className="relative block h-10" />
        <div className="relative block h-10" />
        <AboutBlock />

        <div className="relative block h-6" />

        <div className="relative flex w-full justify-end px-5">
            <ShareRow />
        </div>
    </div>
);

export default Sidebar;
