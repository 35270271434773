import React from 'react';
import InlineTag from '../../atoms/InlineTag/InlineTag';
import Avatar from '../../atoms/Avatar/Avatar';

const AboutBlock: React.FC = () => (
    <>
        <div className="relative mx-auto flex max-w-7xl flex-col">
            <h2 className="text-2xl leading-tight">
                Hello, <br />I am Adrian
            </h2>

            <div className="absolute -top-4 flex w-full justify-end">
                <Avatar imageStyle="business" />
            </div>

            <p className="pt-2 text-gray-500">
                and I am here to talk and write about my everyday experience as CTO in web
                development. You will read about my learnings and challenges and what I&apos;ve
                learned from other leaders in this sector. <br />
                <br />
                My burning topics are modern application architectures with microservices,
                cloud-native, DevOps, and Progressive-Web-Apps.
                <br />
                <br />
                <span className="hidden font-serif">
                    You want to know my opinion on a topic or need advice?
                </span>
            </p>

            <div className="buttonPrimary hidden">
                <a href="https://calendly.com/adrianstanek" target="calendly">
                    <img src="/images/favicon.svg" className="mr-2 h-6 w-6" alt="favicon" />
                    <span>Book a Session</span>
                </a>
            </div>

            <div className="hidden">
                <h3 className="block w-full sm:mt-6 md:mt-14">My topics</h3>
                <p className="text-md mt-0 flex max-w-3xl flex-row flex-wrap text-gray-500">
                    <InlineTag>Small Team development</InlineTag>
                    <InlineTag>Web Technologies</InlineTag>
                    <InlineTag>Solution Engineering</InlineTag>
                    <InlineTag>Progressive-Web-Apps PWA</InlineTag>
                    <InlineTag>DevOps</InlineTag>
                    <InlineTag>Digital Transformation DX</InlineTag>
                </p>
            </div>
        </div>
    </>
);
export default AboutBlock;
