import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
    faFacebook,
    faLinkedin,
    faRedditAlien,
    faTwitter,
    faWhatsapp,
    faXing,
} from '@fortawesome/free-brands-svg-icons';
import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';

const ShareRow: React.FC = () => {
    const [currentUrl, setCurrentUrl] = useState('');

    useEffect(() => {
        setCurrentUrl(window.location.href);
    }, []);

    return (
        <>
            <div className="relative flex flex-row">
                <div className="relative p-3 text-2xl text-secondary transition-all hover:text-primary">
                    <a
                        href={`https://www.xing.com/spi/shares/new?url=${currentUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="share xing"
                    >
                        <FontAwesomeIcon icon={faXing} />
                    </a>
                </div>
                <div className="relative p-3 text-2xl text-secondary transition-all hover:text-primary">
                    <TwitterShareButton url={currentUrl}>
                        <FontAwesomeIcon icon={faTwitter} />
                    </TwitterShareButton>
                </div>
                <div className="relative p-3 text-2xl text-secondary transition-all hover:text-primary">
                    <LinkedinShareButton url={currentUrl}>
                        <FontAwesomeIcon icon={faLinkedin} />
                    </LinkedinShareButton>
                </div>
                <div className="relative p-3 text-2xl text-secondary transition-all hover:text-primary">
                    <FacebookShareButton url={currentUrl}>
                        <FontAwesomeIcon icon={faFacebook} />
                    </FacebookShareButton>
                </div>
                <div className="relative p-3 text-2xl text-secondary transition-all hover:text-primary">
                    <RedditShareButton url={currentUrl}>
                        <FontAwesomeIcon icon={faRedditAlien} />
                    </RedditShareButton>
                </div>
                <div className="relative p-3 text-2xl text-secondary transition-all hover:text-primary">
                    <WhatsappShareButton url={currentUrl}>
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </WhatsappShareButton>
                </div>

                <div className="relative p-3 text-2xl text-secondary transition-all hover:text-primary">
                    <button
                        name="share"
                        aria-label="share"
                        type="button"
                        onClick={() => {
                            // eslint-disable-next-line no-void
                            void navigator.share({ url: currentUrl });
                        }}
                    >
                        <FontAwesomeIcon icon={faShareAlt} />
                    </button>
                </div>
            </div>
        </>
    );
};

export default ShareRow;
